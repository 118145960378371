import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Modal,
  Header,
  Form,
  Input,
  Dropdown,
  Loader,
  Confirm,
  Grid,
  Pagination,
  Icon,
} from "semantic-ui-react";

import {
  configurePaymentPaymenttransaction,
  configurePaymentPaymentmethod,
  PAGE_LIMIT,
} from "services/access/payment";
import { utils } from "services/api-data";
import { useDispatch, useSelector } from "react-redux";
import CommonModal from "components/common/Modal";
import {
  configureOrderOrder,
  configureOrderOrderitem,
} from "services/access/order";
import { OrderCustomerUsername } from "./orders";
import { configureProductProduct } from "services/access/product";

export const GetPaymentMethodName = ({ id }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configurePaymentPaymentmethod(
    utils,
    dispatch,
    state
  );

  const [paymentMethod, setPaymentMethod] = useState();

  useEffect(() => {
    action.fetchPaymentmethodList();
  }, []);

  useEffect(() => {
    if (data?.paymentmethod_fetch_list) {
      setPaymentMethod(data?.paymentmethod_fetch_list);
    }
  }, [data?.paymentmethod_fetch_list]);

  const method = paymentMethod?.find((method) => method.id === id);
  return method ? method?.name : " No payment method found";
};

const ViewPaymentOrderModal = ({
  open,
  setOpenModal,
  selectedData,
  setSelectedPayment,
  paymentMethodConfiguration,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureOrderOrderitem(
    utils,
    dispatch,
    state
  );
  const [orderItems, setOrderItems] = useState();
  const [products, setProducts] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const product_config = configureProductProduct(utils, dispatch, state);

  useEffect(() => {
    if (selectedData) {
      action.fetchOrderitemList(selectedData?.order);
      product_config?.action.fetchProductList();
      paymentMethodConfiguration.action.fetchPaymentmethodList();
    }
  }, [selectedData]);

  useEffect(() => {
    if (data?.orderitem_fetch_list) {
      setOrderItems(data?.orderitem_fetch_list);
    }
  }, [data?.orderitem_fetch_list]);

  useEffect(() => {
    if (product_config?.data?.product_fetch_list) {
      setProducts(product_config?.data?.product_fetch_list);
    }
  }, [product_config?.data?.product_fetch_list]);

  useEffect(() => {
    if (paymentMethodConfiguration?.data?.paymentmethod_fetch_list) {
      setPaymentMethod(
        paymentMethodConfiguration?.data?.paymentmethod_fetch_list
      );
    }
  }, [paymentMethodConfiguration?.data?.paymentmethod_fetch_list]);

  const getProductById = (productId) => {
    const product = products?.find((product) => product.id === productId);
    return product ? product.name : "Unknown Product";
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpenModal(false);
        setSelectedPayment(null);
      }}
    >
      <Modal.Header> Payment Details </Modal.Header>
      <Modal.Content>
        <Header> Order Details </Header>
        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell> Product Name </Table.HeaderCell>
              <Table.HeaderCell> Payment Method </Table.HeaderCell>
              <Table.HeaderCell> Quantity </Table.HeaderCell>
              <Table.HeaderCell> Unit Price </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {orderItems?.length > 0 ? (
              <>
                {orderItems?.map((items) => (
                  <Table.Row>
                    <Table.Cell> {getProductById(items.product)}</Table.Cell>
                    <Table.Cell>
                      {" "}
                      <GetPaymentMethodName
                        id={selectedData?.payment_method}
                      />{" "}
                    </Table.Cell>
                    <Table.Cell>{items?.quantity} </Table.Cell>
                    <Table.Cell> {items?.unit_price} </Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell colSpan={2}>
                    {" "}
                    <Header as={"h3"}> Total Price </Header>{" "}
                  </Table.Cell>
                  <Table.Cell colSpan={2}> {selectedData?.amount} </Table.Cell>
                </Table.Row>
              </>
            ) : (
              <Table.Row centered>
                {" "}
                <Table.Cell colSpan={3}> No order Items found </Table.Cell>{" "}
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() => {
            setOpenModal(false);
            setSelectedPayment(null);
          }}
        >
          {" "}
          Close{" "}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const AdminDashboardPayments = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configurePaymentPaymenttransaction(
    utils,
    dispatch,
    state
  );
  const paymentMethodConfiguration = configurePaymentPaymentmethod(
    utils,
    dispatch,
    state
  );

  const order_config = configureOrderOrder(utils, dispatch, state);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerpage = PAGE_LIMIT;
  const [payments, setPayment] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [options, setOption] = useState([]);
  const [orders, setOrders] = useState();
  useEffect(() => {
    initialize();
    paymentMethodConfiguration?.action?.fetchPaymentmethodList();
    order_config?.action.fetchOrderList();
  }, []);

  useEffect(() => {
    fetchMore(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setLoading(data?.paymenttransaction[`payment_page_${currentPage}_loading`]);
  }, [currentPage, data?.paymenttransaction]);

  useEffect(() => {
    if (order_config?.data.order_fetch_list) {
      setOrders(order_config?.data.order_fetch_list);
    }
  }, [order_config?.data.order_fetch_list]);

  const getOrdersById = (OID) => {
    const order = orders?.find((order) => order.id === OID);

    return order ? <OrderCustomerUsername id={order.customer} /> : "Unknown";
  };

  const fetchMore = (page) => {
    action.getPages(page);
  };

  useEffect(() => {
    const pageCount =
      data?.paymenttransaction[`payment_page_${currentPage}`]?.count || 0;
    setTotalPages(Math.ceil(pageCount / itemsPerpage));
    setPayment(
      data?.paymenttransaction[`payment_page_${currentPage}`]?.results
    );
  }, [data?.paymenttransaction, currentPage]);

  useEffect(() => {
    if (paymentMethodConfiguration?.data?.paymentmethod_fetch_list) {
      setOption(
        paymentMethodConfiguration.data.paymentmethod_fetch_list.map(
          (method) => ({
            key: method.id,
            text: method.name,
            value: method.id,
          })
        )
      );
    } else {
      console.log("No payment methods found");
    }
  }, [paymentMethodConfiguration?.data?.paymentmethod_fetch_list]);

  const [triggerAction, setTriggerAction] = useState(false);

  const handleStatusChange = async ({ value }, id) => {
    console.log(id, value);
    await action.updatePaymenttransactionItem(id, {
      status: value,
    });
    setTriggerAction(true);
    setSelectedPayment(null);
    setShowModal(false);
  };

  // const handleEdit = async () => {
  //   await action.updatePaymenttransactionItem(
  //     selectedPayment?.id,
  //     selectedPayment
  //   );
  //   setTriggerAction(true);
  //   setSelectedPayment(null);
  //   setShowModal(false);
  // };

  const handleDelete = async () => {
    await action.deletePaymenttransactionItem(selectedPayment?.id);
    setTriggerAction(true);
    setDeleteModal(false);
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  useEffect(() => {
    if (triggerAction) {
      if (!loading) {
        fetchMore(currentPage);
        setTriggerAction(false);
      }
    }
  }, [loading, triggerAction]);

  return (
    <Container>
      <Header as="h1">Payments</Header>
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Customer Name</Table.HeaderCell>
            <Table.HeaderCell>Total Amount</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Loader active inline />
          ) : payments?.length > 0 ? (
            payments.map((payment) => (
              <Table.Row key={payment.id}>
                <Table.Cell>{payment.id}</Table.Cell>
                <Table.Cell> {getOrdersById(payment.order)} </Table.Cell>
                <Table.Cell>{payment.amount}</Table.Cell>
                <Table.Cell>
                  {" "}
                  <Dropdown
                    placeholder="Select Payment Status"
                    fluid
                    selection
                    options={[
                      {
                        key: "Succeeded",
                        value: "Succeeded",
                        text: "Succeeded",
                      },
                      { key: "Pending", value: "Pending", text: "Pending" },
                    ]}
                    value={payment?.status}
                    onChange={(e, { value }) =>
                      handleStatusChange({ value }, payment.id)
                    }
                  />{" "}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    color="black"
                    basic
                    icon
                    onClick={() => {
                      setSelectedPayment(payment);
                      setOpenModal(true);
                    }}
                  >
                    <Icon name="eye" />
                  </Button>
                  {/* <Button
                    color="blue"
                    icon
                    basic
                    onClick={() => {
                      setSelectedPayment(payment);
                      setShowModal(true);
                    }}
                  >
                    <Icon name="edit" />
                  </Button> */}
                  <Button
                    basic
                    icon
                    color="red"
                    onClick={() => {
                      setSelectedPayment(payment);
                      setDeleteModal(true);
                    }}
                  >
                    <Icon name="trash" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              {" "}
              <Table.Cell colSpan={5} textAlign="center">
                {" "}
                <h3> No Payments Found </h3>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Grid centered>
        <Pagination
          pointing
          secondary
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Grid>
      {/* Payment View Modal */}
      <ViewPaymentOrderModal
        open={openModal}
        setOpenModal={setOpenModal}
        selectedData={selectedPayment}
        setSelectedPayment={setSelectedPayment}
        paymentMethodConfiguration={paymentMethodConfiguration}
      />

      {/* Payment Edit Modal */}
      {/* <CommonModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        button_name={"Update"}
        title={"Edit Payment"}
        button_action={handleEdit}
      >
        <Form>
          <Form.Field>
            <label> Payment Method </label>
            <Dropdown
              placeholder="Select Payment Method"
              fluid
              selection
              options={options}
              value={selectedPayment?.payment_method}
              onChange={(e, { value }) =>
                setSelectedPayment({
                  ...selectedPayment,
                  payment_method: value,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <label> Total Amount </label>
            <Input
              placeholder="Enter Total Amount"
              value={selectedPayment?.amount}
              onChange={(e) =>
                setSelectedPayment({
                  ...selectedPayment,
                  amount: e.target.value,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <label> Status </label>
            <Dropdown
              placeholder="Select Payment Status"
              fluid
              selection
              options={[
                { key: "succeeded", value: "succeeded", text: "Succeeded" },
                { key: "pending", value: "pending", text: "Pending" },
              ]}
              value={selectedPayment?.status}
              onChange={(e, { value }) =>
                setSelectedPayment({ ...selectedPayment, status: value })
              }
            />
          </Form.Field>
        </Form>
      </CommonModal> */}

      <Confirm
        open={deleteModal}
        header="Delete this payment"
        content={
          <div style={{ padding: 25 }}>
            Are you sure you want to delete this user({" "}
            <b> {selectedPayment?.customerName} </b> ) payment?
          </div>
        }
        onCancel={() => {
          setDeleteModal(false);
          setSelectedPayment(null);
        }}
        onConfirm={() => handleDelete()}
      />
    </Container>
  );
};

export default AdminDashboardPayments;
