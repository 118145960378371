import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getCartItemCount } from "services/cart";
import {
  Container,
  Menu,
  Image,
  Icon,
  Sidebar,
  Button,
  Label,
  Input,
} from "semantic-ui-react";
import Logo from "../../assets/logo.png"; // Assuming Logo is an image path
import { clearUserData } from "services/api-data/src/utils/tokenHandler";
import { useDispatch, useSelector } from "react-redux";
import { configureAccountUserprofile } from "services/access/account";
import { utils } from "services/api-data/src/App";

const Nav = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [cartCount, setCartCount] = useState(getCartItemCount());
  const [searchTerm, setSearchTerm] = useState("");
  const [profileData, setProfileData] = useState();
  const [searchActive, setSearchActive] = useState(false);
  const tk = localStorage.getItem("tk");
  const user_id = localStorage.getItem("user_id");
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(
    utils,
    dispatch,
    state
  );

  useEffect(() => {
    if (user_id) {
      action.fetchUserprofileItem(user_id);
    }
  }, [user_id]);

  // useEffect(() => {
  //   setCartCount(getCartItemCount());
  // }, []);

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      setProfileData(data?.userprofile_fetch_item);
    }
  }, [data?.userprofile_fetch_item]);

  const handleMenuClick = () => {
    setMobileMenu(!mobileMenu);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" }); // Adjust breakpoint as needed

  const mainMenu = [
    {
      label: "Home",
      link: "/",
    },
    {
      label: "Brands",
      link: "/brands",
    },
    {
      label: "Products",
      link: "/products",
    },

    {
      label: "Contact Us",
      link: "/helpcenter",
    },

    // {
    //   label: "Language selection",
    //   link: "/language",
    // },
  ];
  const { hash } = useLocation();

  useEffect(() => {
    setCartCount(getCartItemCount());
  }, [hash]);

  const navigate = useNavigate();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/search/${searchTerm}`);
    }
  };

  const AuthOption = () => {
    return (
      <>
        <Menu.Item as={Link} to="/admin" style={{border: '1px solid #ccc'}}>
          Login
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/register"
          style={{ backgroundColor: "#093d57", color: "white" }}
        >
          Register
        </Menu.Item>
      </>
    );
  };

  return (
    <div style={{ boxShadow: "0px 1px 10px #ccc" }}>
      <Container>
        <Menu secondary>
          {searchActive && isMobile ? (
            <Input
              fluid
              icon="search"
              iconPosition="left"
              style={{
                width: "100%",
                minWidth: 70,
                maxWidth: "100%",
                height: 40,
                marginTop: 20,
                marginBottom: 20,
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => setSearchActive(true)}
              onBlur={() => setSearchActive(false)}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <>
              <Menu.Item as={Link} to="/">
                <Image
                  src={Logo}
                  style={{ maxHeight: 60, minHeight: 40, height: "10vw" }}
                />
              </Menu.Item>

              <Input
                fluid
                icon="search"
                placeholder="search here"
                iconPosition="left"
                style={{
                  width: "100%",
                  minWidth: 70,
                  maxWidth: "50%",
                  height: 40,
                  marginTop: 20,
                  marginBottom: 20,marginRight: 50,
                  borderRadius: 40,
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setSearchActive(true)}
                onBlur={() => setSearchActive(false)}
                onKeyDown={handleKeyDown}
              />
              <Menu.Menu position="right">
                {/* Desktop Menu Items */}
                {!isMobile && (
                  <>
                    {mainMenu.map((item, idx) => (
                      <Menu.Item as={Link} to={item.link} key={idx}>
                        {item.label}
                      </Menu.Item>
                    ))}
                  </>
                )}
                <Menu.Item position="right">
                  {!isMobile && (
                    <>
                      {tk ? (
                        <>
                          {!isMobile && (
                            <>
                              {profileData?.profile_type !== "SUPPLIER" ? (
                                <Menu.Item as={Link} to="/profile">
                                  <Icon size="large" name="user" />{" "}
                                  {profileData?.first_name}
                                </Menu.Item>
                              ) : (
                                <Menu.Item as={Link} to="/profile">
                                  <Icon size="large" name="user" />{" "}
                                  {profileData?.business_name}
                                </Menu.Item>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <AuthOption />
                      )}
                    </>
                  )}
                </Menu.Item>
                {/* Mobile Menu Toggle */}
                {isMobile && (
                  <Menu.Item
                    onClick={handleMenuClick}
                    style={{
                      boxShadow: "0px 0px 2px grey",
                      backgroundColor: "white",
                    }}
                  >
                    {mobileMenu ? (
                      <Icon name="close" />
                    ) : (
                      <>
                        <Icon name="bars" />
                        Menu
                      </>
                    )}
                  </Menu.Item>
                )}
              </Menu.Menu>
              {/* Mobile Menu Items (conditionally rendered) */}
              {isMobile && mobileMenu && (
                <Sidebar
                  as={Menu}
                  animation="overlay"
                  icon="labeled"
                  inverted
                  vertical
                  visible={mobileMenu}
                  width="thin"
                  direction="right"
                  style={{ position: "absolute", zIndex: 1001 }}
                >
                  <Menu.Menu mobile>
                    {isMobile && (
                      <Menu.Item onClick={handleMenuClick}>
                        {mobileMenu ? (
                          <Icon name="close" />
                        ) : (
                          <Icon name="bars" />
                        )}
                      </Menu.Item>
                    )}
                    {profileData?.profile_type !== "SUPPLIER" ? (
                      <Menu.Item as={Link} to="/profile">
                        <Icon size="large" name="user" />{" "}
                        {profileData?.first_name}
                      </Menu.Item>
                    ) : (
                      <Menu.Item as={Link} to="/profile">
                        <Icon size="large" name="user" />{" "}
                        {profileData?.business_name}
                      </Menu.Item>
                    )}
                    {mainMenu.map((item, idx) => (
                      <Menu.Item as={Link} to={item.link} key={idx}>
                        {item.label}
                      </Menu.Item>
                    ))}
                    {!tk && (
                      <div
                        style={{
                          borderTop: "1px solid #333",
                          margin: 20,
                          padding: 10,
                        }}
                      >
                        <AuthOption />
                      </div>
                    )}
                  </Menu.Menu>
                </Sidebar>
              )}
            </>
          )}
          {cartCount > 0 && (
            <Menu.Item
              as={Link}
              to="/cart"
              style={{
                position: "fixed",
                right: 10,
                backgroundColor: "white",
                borderRadius: 60,
                paddingRight: 20,
                paddingLeft: 20,
                boxShadow: "0px 0px 2px grey",
                top: 160,
                zIndex: 100,
              }}
            >
              <Icon size="large" name="cart" />{" "}
              <Label circular color="red" floating size="small">
                {cartCount}
              </Label>
            </Menu.Item>
          )}
        </Menu>
      </Container>
    </div>
  );
};

export default Nav;
