const PAGE_LIMIT = 8;
const BIG_LIMIT = 1000;

export const configureAccountUserprofile = (utils, dispatch, state) => ({
  initialize: () => {},
  data: {
    userprofile_fetch_list: state["account"]?.userprofile_list,
    userprofile_fetch_item: state["account"]?.userprofile_item,
    userprofile_create_item: state["account"]?.userprofile_item,
    userprofile_update_item: state["account"]?.userprofile_item,
    userprofile_delete_item: state["account"]?.deleted_userprofile,
  },
  action: {
    fetchUserprofileList: () => {
      const userprofile_fetch_list_request = {
        dispatch,
        request: ["account", "userprofile", "list"],
        request_info: {},
        auth: true,
      };
      utils.action(userprofile_fetch_list_request);
    },
    fetchUserprofileItem: (id) => {
      const userprofile_fetch_item_request = {
        dispatch,
        request: ["account", "userprofile", "read"],
        request_info: { id },
        auth: true,
      };
      utils.action(userprofile_fetch_item_request);
    },
    createUserprofileItem: (payload) => {
      const userprofile_create_item_request = {
        dispatch,
        request: ["account", "userprofile", "create"],
        request_info: payload,
        auth: true,
      };
      utils.action(userprofile_create_item_request);
    },
    updateUserprofileItem: (user_id, payload) => {
      let request_info;
      if (typeof payload.profile_pic === "string") {
        const { profile_pic, ...remainingData } = payload;
        request_info = { lookup_field: "user_id", user_id, ...remainingData };
      } else {
        request_info = { lookup_field: "user_id", user_id, ...payload };
      }
      const userprofile_update_item_request = {
        dispatch,
        request: ["account", "userprofile", "update"],
        request_info,
        auth: true,
      };
      utils.action(userprofile_update_item_request);
    },
    deleteUserprofileItem: (id) => {
      const userprofile_delete_item_request = {
        dispatch,
        request: ["account", "userprofile", "delete"],
        request_info: { id },
        auth: true,
      };
      utils.action(userprofile_delete_item_request);
    },
  },
});
