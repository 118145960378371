import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Image,
  Header,
  Icon,
  Statistic,
  Button,
  Modal,
  Popup,
  Segment,
  Divider,
} from "semantic-ui-react";
import { useParams, useNavigate } from "react-router-dom";
import { configureProductProduct } from "services/access/product";
import {
  configureOrderOrder,
  configureOrderOrderitem,
} from "services/access/order";
import { utils } from "services/api-data";
import { useDispatch, useSelector } from "react-redux";
import { getCartItems, updateCartItems } from "services/cart";
import { toast } from "react-toastify";
import { ShareButtons } from "components/product";
import CheckoutForm from "components/payment/CheckoutForm";
import { SITE_URL } from "../../App";

const ProductDetailComponent = ({ admin, productData }) => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [product, setProduct] = useState();
  const [reviews, setReviews] = useState([]);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [triggerActions, setTriggerAction] = useState(false);
  const [cartProducts, setCartProducts] = useState(getCartItems());
  const [selectedColor, setSelectedColor] = useState();
  const [selectedShoeSize, setSelectedShoeSize] = useState();
  const [open, setOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState([
    {
      quantity: 1,
    },
  ]);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureProductProduct(
    utils,
    dispatch,
    state
  );
  const orderConfig = configureOrderOrder(utils, dispatch, state);
  const orderItemConfig = configureOrderOrderitem(utils, dispatch, state);
  // Simulate fetching product data based on route parameter
  useEffect(() => {
    if (productId) {
      action.fetchProductItem(productId);
    }
  }, [productId]);

  useEffect(() => {
    if (data?.product_fetch_item?.min_order_quantity) {
      setOrderDetails({ quantity: data.product_fetch_item.min_order_quantity });
      setProduct(data?.product_fetch_item);
    }
  }, [data?.product_fetch_item?.min_order_quantity]);

  const handleQuantityChange = (newQuantity) => {
    if (newQuantity >= data?.product_fetch_item?.min_order_quantity) {
      setOrderDetails({ ...orderDetails, quantity: newQuantity });
    }
  };

  const handleOpenReviewModal = () => setOpenReviewModal(true);
  const handleCloseReviewModal = () => setOpenReviewModal(false);

  const placeTheOrder = () => {
    setProduct({ ...product, quantity: orderDetails?.quantity });
    setOpen(true);
  };

  const addToCart = () => {
    const newCartProduct = {
      product: data?.product_fetch_item?.id,
      name: data?.product_fetch_item?.name,
      price: data?.product_fetch_item?.price,
      quantity: orderDetails.quantity,
      description: data?.product_fetch_item?.description,
      image: data?.product_fetch_item?.image,
      min_order_quantity: data?.product_fetch_item?.min_order_quantity,
      certifications: data?.product_fetch_item?.certifications,
      organic: data?.product_fetch_item?.organic,
      bulk_discount: data?.product_fetch_item?.bulk_discount,
    };

    const existingProductIndex = cartProducts.findIndex(
      (item) => item.product === newCartProduct.product
    );

    if (existingProductIndex >= 0) {
      cartProducts[existingProductIndex].quantity += newCartProduct.quantity;
    } else {
      cartProducts.push(newCartProduct);
    }
    updateCartItems(cartProducts, setCartProducts, navigate);

    toast.success(
      `${newCartProduct?.quantity}  ${
        newCartProduct?.name
      } has been added to cart.
      ${
        cartProducts[existingProductIndex]?.quantity
          ? `Total Quantity - ${
              cartProducts[existingProductIndex]?.quantity || 0
            }`
          : ""
      }
      `
    );
    setTriggerAction(true);
  };

  useEffect(() => {
    if (triggerActions) {
      updateCartItems(cartProducts, setCartProducts, navigate);
      setTriggerAction(false);
    }
  }, [triggerActions, cartProducts]);

  const currency = "Rs.";
  const certificates_conditions =
    data?.product_fetch_item?.certifications || productData?.certifications;

  const organic_conditions =
    data?.product_fetch_item?.organic || productData?.organic;

  const bulkDiscount_condition =
    data?.product_fetch_item?.bulk_discount || productData?.bulk_discount;

  const all_condtions =
    certificates_conditions && organic_conditions && bulkDiscount_condition;

  const colors = [
    {
      name: "red",
    },
    {
      name: "black",
    },
    {
      name: "grey",
    },
    {
      name: "white",
    },
  ];

  const shoeSize = [
    {
      size: 7,
      available: true,
    },
    {
      size: 7.5,
      available: true,
    },
    {
      size: 8,
      available: true,
    },
    {
      size: 8.5,
      available: false,
    },
    {
      size: 9,
      available: false,
    },
    {
      size: 9.5,
      available: true,
    },
    {
      size: 10,
      available: true,
    },
  ];

  return (
    <>
      <Grid columns={2} stackable doubling>
        <Grid.Row>
          <Grid.Column width={8}>
            <div style={{ position: "relative", display: "inline-block" }}>
              {!admin && data?.product_fetch_item?.stock_quantity === 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    color: "red",
                    fontWeight: "bold",
                    backgroundColor: "rgba(255, 255, 255, 2)",
                    padding: "5px",
                    borderRadius: "5px",
                    zIndex: 1,
                  }}
                >
                  Out of Stock
                </div>
              )}
              <Image
                src={
                  admin ? productData?.image : data?.product_fetch_item?.image
                }
                alt={
                  admin ? productData?.name : data?.product_fetch_item?.title
                }
                size="large"
                style={{ display: "block" }}
              />
            </div>
            <Header as="h2">
              {admin ? productData?.name : data?.product_fetch_item?.name}
            </Header>
            <p>
              {admin
                ? productData?.description
                : data?.product_fetch_item?.description}
            </p>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Statistic horizontal size={admin ? "mini" : "small"}>
                    <Statistic.Value>
                      {currency}
                      {admin
                        ? productData?.price
                        : data?.product_fetch_item?.price}
                    </Statistic.Value>
                    <Statistic.Label>Cost per Unit</Statistic.Label>
                  </Statistic>
                  <p>
                    Min. Order Quantity:{" "}
                    {admin
                      ? productData?.min_order_quantity
                      : data?.product_fetch_item?.min_order_quantity}
                  </p>
                </Grid.Column>
                {!admin && (
                  <Grid.Column width={8} textAlign="right">
                    {/* <Button
                      basic
                      icon="star outline"
                      onClick={handleOpenReviewModal}
                    />
                    {`  ${reviews.length} Reviews`} */}

                    <Popup
                      on={"click"}
                      trigger={
                        <Button icon color="black" basic labelPosition="right">
                          {" "}
                          <Icon name="share alternate" />
                          Share
                        </Button>
                      }
                      content={
                        <ShareButtons
                          url={`${SITE_URL}product/${data?.product_fetch_item?.id}`}
                          title="Check out this product"
                        />
                      }
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
              {all_condtions && (
                <Grid.Row>
                  <Grid.Column>
                    {certificates_conditions && (
                      <p>
                        <Icon name="certificate" /> Certifications:{" "}
                        {admin
                          ? productData?.certifications
                          : data?.product_fetch_item?.certifications}
                      </p>
                    )}
                    {organic_conditions && (
                      <p>
                        <Icon name="leaf" /> Organic Certified
                      </p>
                    )}
                    {bulkDiscount_condition && (
                      <p>
                        Bulk Discounts Available! See details on product inquiry
                        with supplier.
                      </p>
                    )}
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Grid.Column>
          <Grid.Column width={8}>
            {!admin && (
              <Grid>
                <Grid>
                  <Segment padded>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Header as="h3">
                            <Icon name="shopping cart" />
                            <Header.Content>
                              You are about to order:
                              <Header.Subheader>
                                <i>{data?.product_fetch_item?.name}</i>
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Grid.Column>
                      </Grid.Row>

                      {/* <Divider />
                      <Grid.Row>
                        <Grid.Column>
                          <h3> Select Shoe size </h3>
                          <div>
                            {shoeSize?.map((shoe) => (
                              <Button
                                basic={selectedShoeSize !== shoe?.size}
                                color="black"
                                onClick={() => setSelectedShoeSize(shoe.size)}
                                disabled={!shoe.available}
                              >
                                {shoe.size}
                              </Button>
                            ))}
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      <Divider />
                      <Grid.Row>
                        <Grid.Column>
                          <h3> Select Shoe Color </h3>
                          <div>
                            {colors.map((color) => (
                              <Button
                                size="big"
                                circular
                                color={color.name}
                                icon
                                onClick={() => setSelectedColor(color)}
                              >
                                <Icon
                                  name={
                                    selectedColor?.name === color?.name
                                      ? "checkmark"
                                      : ""
                                  }
                                />
                              </Button>
                            ))}
                          </div>
                        </Grid.Column>
                      </Grid.Row> */}
                      <Divider />
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <h3>Order Quantity:</h3>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Button
                              icon="minus"
                              onClick={() =>
                                handleQuantityChange(orderDetails.quantity - 1)
                              }
                              size="small"
                              disabled={
                                orderDetails.quantity <=
                                data?.product_fetch_item?.min_order_quantity
                              }
                            />
                            <input
                              type="number"
                              min={data?.product_fetch_item?.min_order_quantity}
                              value={orderDetails.quantity}
                              onChange={(e) =>
                                handleQuantityChange(parseInt(e.target.value))
                              }
                              style={{
                                width: "100px",
                                textAlign: "center",
                                padding: 5,
                                margin: 10,
                              }}
                            />
                            <Button
                              icon="plus"
                              size="small"
                              onClick={() =>
                                handleQuantityChange(orderDetails.quantity + 1)
                              }
                            />
                          </div>
                        </Grid.Column>
                      </Grid.Row>

                      <Divider />

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <p style={{ fontSize: 16 }}>
                            <b>Cost per Unit:</b> {currency}{" "}
                            {data?.product_fetch_item?.price}
                          </p>
                        </Grid.Column>
                      </Grid.Row>

                      <Divider />

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <p style={{ fontSize: 16 }}>
                            <b>Total Cost:</b> {currency}{" "}
                            {data?.product_fetch_item?.price *
                              orderDetails.quantity}
                          </p>
                          <p style={{ fontSize: 14, color: "grey" }}>
                            <i>
                              <b>Note</b>: The price above is inclusive of VAT
                            </i>
                          </p>
                        </Grid.Column>
                      </Grid.Row>

                      <Divider />

                      <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                          <Button
                            primary
                            content="Buy Now"
                            onClick={() => placeTheOrder()}
                            disabled={
                              data?.product_fetch_item?.stock_quantity === 0
                            }
                          />
                          <Button
                            color="black"
                            icon
                            labelPosition="right"
                            onClick={() => addToCart()}
                            disabled={
                              data?.product_fetch_item?.stock_quantity === 0
                            }
                          >
                            {" "}
                            <Icon name="in cart" />
                            Add to Cart{" "}
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                  {/* <Grid.Row>
                    <Grid.Column width={8}>
                      <h3>Delivery Location:</h3>
                      <textarea
                        rows={2}
                        value={orderDetails.deliveryLocation}
                        placeholder='Details of the delivery location'
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <div>
                        <h3>Contact Details:</h3>
                        Email:<br/>
                        <input
                          type='text'
                          value={orderDetails.deliveryLocation}
                          placeholder='Details of the delivery location'
                        />
                      </div>
                      <div>
                        Phone Number:<br/>
                        <input
                          type='text'
                          value={orderDetails.deliveryLocation}
                          placeholder='Details of the delivery location'
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Button basic content='Clear Order' onClick={() => setOrderDetails({ quantity: 0 })} /> */}
                </Grid>
              </Grid>
            )}
            {/*<Button primary icon='comment alternate outline' content='Chat with Supplier' />*/}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* Buy now Modal */}
      <Modal
        dimmer="inverted"
        open={open}
        onClose={() => setOpen(false)}
        size="large"
        closeIcon
        className="custom-close-icon"
      >
        <>
          <Modal.Header>Checkout | Proceed to payment</Modal.Header>
          <Modal.Content>
            {" "}
            <CheckoutForm is_cart={false} product_data={product} />
          </Modal.Content>
        </>
      </Modal>
      {/* Review Modal */}
      <Modal open={openReviewModal} onClose={handleCloseReviewModal}>
        <Modal.Header>Product Reviews ({reviews.length})</Modal.Header>
        <Modal.Content>
          {reviews.map((review) => (
            <div key={review.id}>
              <p>
                <strong>{review.author}</strong> - {review.rating} stars
              </p>
              <p>{review.comment}</p>
              <hr />
            </div>
          ))}
          {!reviews.length && <p>No reviews yet.</p>}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ProductDetailComponent;
