import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link, useNavigate } from "react-router-dom";
import { FacebookShare, WeiboShare, WhatsappShare } from "react-share-kit";
import { toast } from "react-toastify";
import { Button, Card, Divider, Icon, Popup } from "semantic-ui-react";
import { getCartItems, updateCartItems } from "services/cart";
import {SITE_URL} from "App";

export const ShareButtons = ({ url, title }) => {
  const [copied, setCopied] = useState(0);
  useEffect(() => {
    if (copied > 0) {
      toast.info("Link has been copied to clipboard");
    }
  }, [copied]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        width: "200px",
      }}
    >
      <CopyToClipboard text={url} onCopy={() => setCopied(copied + 1)}>
        <Icon
          circular
          style={{
            minWidth: 32,
            minHeight: 32,
            padding: 5,
            cursor: "pointer",
          }}
          name="chain"
        />
      </CopyToClipboard>
      <FacebookShare
        round="true"
        size={"32px"}
        url={url}
        quote={title}
        title={title}
        // buttonTitle="Facebook"
      />

      <WhatsappShare round="true" size={"32px"} url={url} title={title} />
      {/* <WeiboShare round="true" size={"32px"} url={url} title={title} /> */}
    </div>
  );
};

const ProductCard = ({ product_data, fetchMore, count, search }) => {
  const [cartProducts, setCartProducts] = useState(getCartItems());
  const [triggerActions, setTriggerAction] = useState(false);

  const navigate = useNavigate();

  const addToCart = (selectedProduct) => {
    const newCartProduct = {
      product: selectedProduct?.id,
      name: selectedProduct?.name,
      price: selectedProduct?.price,
      quantity: selectedProduct?.min_order_quantity,
      description: selectedProduct?.description,
      image: selectedProduct?.image,
      min_order_quantity: selectedProduct?.min_order_quantity,
      certifications: selectedProduct?.certifications,
      organic: selectedProduct?.organic,
      bulk_discount: selectedProduct?.bulk_discount,
    };

    const existingProductIndex = cartProducts.findIndex(
      (item) => item.product === newCartProduct.product
    );

    if (existingProductIndex >= 0) {
      cartProducts[existingProductIndex].quantity += newCartProduct.quantity;
    } else {
      cartProducts.push(newCartProduct);
    }
    updateCartItems(cartProducts, setCartProducts, navigate);
    toast.success(
      `${newCartProduct?.quantity}  ${
        newCartProduct?.name
      } has been added to cart.
      ${
        cartProducts[existingProductIndex]?.quantity
          ? `Total Quantity - ${
              cartProducts[existingProductIndex]?.quantity || 0
            }`
          : ""
      }
      `
    );

    setTriggerAction(true);
  };

  useEffect(() => {
    if (triggerActions) {
      updateCartItems(cartProducts, setCartProducts, navigate);
      setTriggerAction(false);
    }
  }, [triggerActions, cartProducts]);

  const currency = "Rs.";

  return (
    <>
      <Card.Group itemsPerRow={4} stackable>
        {product_data?.length > 0 ? (
          product_data?.map((product) => (
            <Card key={product.id}>
              <Card
                as={Link}
                to={`/product/${product.id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div
                  alt={product.name}
                  style={{
                    height: 300,
                    backgroundImage: `url("${product.image}")`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    paddingBottom: "15px",
                  }}
                />

                <Card.Content>
                  <Card.Header>{product.name}</Card.Header>
                  <Card.Meta>{product.description}</Card.Meta>
                  <Card.Description>
                    Price: {currency} {product.price}
                  </Card.Description>
                  <Card.Description>
                    {" "}
                    Minimum Order: {product.min_order_quantity}{" "}
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card.Content extra>
                {" "}
                <Button
                  color="black"
                  icon
                  labelPosition="right"
                  onClick={() => {
                    addToCart(product);
                  }}
                >
                  {" "}
                  <Icon name="in cart" />
                  Add to Cart{" "}
                </Button>
                <Popup
                  on={"click"}
                  trigger={
                    <Button icon color="black" basic>
                      {" "}
                      <Icon name="share alternate" />
                    </Button>
                  }
                  content={
                    <ShareButtons
                      url={`${SITE_URL}product/${product.id}`}
                      title="Check out this product"
                    />
                  }
                />
              </Card.Content>
            </Card>
          ))
        ) : (
          <Card style={{ height: "300px" }}>
            <Card.Content>
              <Card.Header>
                <div>No Product Founds</div>
              </Card.Header>
            </Card.Content>
          </Card>
        )}
      </Card.Group>
      {!search && product_data?.length > 0 && count > product_data?.length && (
        <Button fluid style={{ marginTop: "15px" }} onClick={() => fetchMore()}>
          {" "}
          Explore More{" "}
        </Button>
      )}
    </>
  );
};

export default ProductCard;
