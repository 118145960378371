import React from "react";
import NoImage from "assets/no-image.png";
import { Button, Container, Icon, Popup } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { ShareButtons } from "components/product";
import { SITE_URL } from "App";

const ImageView = ({ url, height, width, style, name, link, id }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <div style={{ float: "inline-end" }}>
        <Popup
          on={"click"}
          trigger={
            <Button
              icon
              // color="black"
              // basic
              style={{
                alignSelf: "flex-end",
                cursor: "pointer",
                border: "none",
                backgroundColor: "rgba(0,0,0,0)",
                color: "white",
              }}
            >
              {" "}
              <Icon
                name="share alternate"
                size="large"
                style={{
                  filter: "drop-shadow(0px 0px 1px rgba(0, 0, 0, 1))",
                }}
              />
            </Button>
          }
          content={
            <ShareButtons
              url={`${SITE_URL}product/${id}`}
              title="Check out this product"
            />
          }
        />
      </div>
      <div
        onClick={() => navigate(`/product/${id}`)}
        style={{
          backgroundImage: `url("${url || NoImage}")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: height || "100vh",
          width: width || "100%",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          // transition: "transform 0.2s",
          ...style,
        }}
      >
        {name && (
          <div
            style={{
              maxWidth: "100%",
              position: "absolute",
              bottom: 20,
              fontSize: 20,
              fontWeight: 'bold',
              fontStyle: "italic",
              color: "black",
              padding: "20px",
            }}
          >
            <span style={{ lineHeight: 1.7, borderRadius: 2, backgroundColor: "rgba(255,255,255,0.8)", paddingRight: 10, paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}>
              {name.toUpperCase()}
            </span>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ImageView;
