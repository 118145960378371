import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Message,
  Modal,
  Radio,
  Segment,
  Table,
} from "semantic-ui-react";
import QR from "assets/QR.png";
import { useDispatch, useSelector } from "react-redux";
import { configureAccountUserprofile } from "services/access/account";
import { utils } from "services/api-data/src/App";
import {
  configureOrderOrder,
  configureOrderOrderitem,
} from "services/access/order";
import { configureDeliveryDeliveryorder } from "services/access/delivery";
import { toast } from "react-toastify";
import { API_BASE_URL, SITE_URL } from "App";
import { Link, useNavigate } from "react-router-dom";
import { configurePaymentPaymentmethod } from "services/access/payment";

export default function CheckoutForm({ is_cart, product_data }) {
  const user_id = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({
    city: "",
    address: "",
    postalCode: "",
    country: "",
    state: "",
  });
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [paymentMethod, setPaymentMethod] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [paymentMethodId, setPaymentMethodId] = useState();
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(
    utils,
    dispatch,
    state
  );
  const orderConfig = configureOrderOrder(utils, dispatch, state);
  const orderItemConfig = configureOrderOrderitem(utils, dispatch, state);
  const delieveryConfig = configureDeliveryDeliveryorder(
    utils,
    dispatch,
    state
  );
  const paymentConfig = configurePaymentPaymentmethod(utils, dispatch, state);

  useEffect(() => {
    if (user_id) {
      action.fetchUserprofileItem(user_id);
    }
  }, [user_id]);

  useEffect(() => {
    paymentConfig.action.fetchPaymentmethodList();
  }, []);

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      const fetchedProfile = data.userprofile_fetch_item;
      setProfileData(fetchedProfile);
      setPhoneNumber(fetchedProfile?.contact_number);

      const profileAddress = {
        city: fetchedProfile.city,
        address: fetchedProfile.address,
        postalCode: fetchedProfile.postal_code,
        country: fetchedProfile.country,
        state: fetchedProfile.state,
      };
      setAddresses([profileAddress]);
      setSelectedAddress(profileAddress);
      if (fetchedProfile?.contact_number === "") {
        setOpenModal(true);
      }
    } else {
      setOpenModal(true);
    }
  }, [data?.userprofile_fetch_item]);

  useEffect(() => {
    if (phoneNumber === "") {
      setOpenModal(true);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (paymentConfig.data.paymentmethod_fetch_list) {
      setPaymentMethod(paymentConfig.data.paymentmethod_fetch_list);
    }
  }, [paymentConfig.data.paymentmethod_fetch_list]);
  console.log(paymentMethod);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const calculateTotalAmount = () => {
    if (is_cart) {
      return product_data
        .map((c) => c.price * c.quantity)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    } else {
      return product_data.price * product_data.quantity;
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    try {
      const total_amount = calculateTotalAmount();
      const orderResponse = await orderConfig.action?.createOrderItem({
        total_amount: total_amount,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleOrderCreation = async () => {
      const order_id = orderConfig?.data?.order_create_item?.id;
      if (!order_id) {
        toast.error("Order isn't created");
        return;
      }

      try {
        const orderItemsPromises = (
          is_cart ? product_data : [product_data]
        ).map((product) =>
          orderItemConfig.action.createOrderitemItem({
            order: order_id,
            product: is_cart ? product.product : product.id,
            quantity: product.quantity,
            unit_price: product.price,
          })
        );
        await Promise.all(orderItemsPromises);

        const deliveryOrder =
          await delieveryConfig.action.createDeliveryorderItem({
            order: order_id,
            address: selectedAddress.address,
            city: selectedAddress.city,
            state: selectedAddress.state,
            postal_code: selectedAddress.postalCode,
            country: selectedAddress.country,
            contact_number: phoneNumber,
          });
        navigate(
          `/checkout/checkout-success/${order_id}?payment_method=${paymentMethodId}`
        );
        setDataLoading(false);
      } catch (error) {
        console.error("Error during order creation:", error);
      }
    };

    if (orderConfig?.data?.order_create_item?.id) {
      handleOrderCreation();
      setDataLoading(true);
    }
  }, [orderConfig?.data?.order_create_item?.id]);

  const handleNewAddressChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleAddNewAddress = () => {
    setAddresses([...addresses, newAddress]);
    setSelectedAddress(newAddress);
    setNewAddress({
      city: "",
      address: "",
      postalCode: "",
      country: "",
      state: "",
    });
    setShowNewAddressForm(false);
  };

  const formatAddress = (address) => {
    const { address: addr, city, state, postalCode, country } = address;
    const parts = [addr, city, state, postalCode, country].filter(
      (part) => part && part.trim() !== ""
    );
    return parts.join(", ");
  };

  const currency = "Rs.";

  return (
    <>
      <Header as={"h3"}> Product Details </Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Product Name</Table.HeaderCell>
            <Table.HeaderCell>Quantity</Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {is_cart ? (
            product_data?.map((product, index) => (
              <Table.Row key={index}>
                <Table.Cell>{product?.name}</Table.Cell>
                <Table.Cell>{product?.quantity}</Table.Cell>
                <Table.Cell>{product?.price}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell>{product_data?.name}</Table.Cell>
              <Table.Cell>{product_data?.quantity}</Table.Cell>
              <Table.Cell>{product_data?.price}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="2">Total Price</Table.HeaderCell>
            <Table.HeaderCell>
              {currency} {calculateTotalAmount()}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      <Grid doubling divided="vertically" columns={"equal"}>
        <Grid.Row>
          <Grid.Column>
            <Grid.Row>
              <Segment>
                <Header as="h3">Phone Details</Header>
                <Container>
                  {user_id ? (
                    phoneNumber ? (
                      <div>Contact Number: {phoneNumber}</div>
                    ) : (
                      <Message info>
                        <p>
                          It looks like you haven't added your phone number.
                          Visit your profile and add your contact information.
                        </p>
                        <Button
                          fluid
                          secondary
                          as="a"
                          href="/profile"
                          target="_blank"
                          rel="noopener noreferrer"
                          inverted
                        >
                          Go to Profile Page
                        </Button>
                      </Message>
                    )
                  ) : (
                    <Form>
                      <Form.Field>
                        <label>Enter your phone number:</label>
                        <Input
                          type="tel"
                          placeholder="Phone number"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </Form.Field>
                    </Form>
                  )}
                </Container>
              </Segment>
            </Grid.Row>
            <Grid.Row>
              <Segment>
                <Header as={"h4"}> Delivery Address </Header>
                <Container>
                  <Message info>
                    {" "}
                    To get your delivery in time please add your precise
                    address.{" "}
                  </Message>
                  {addresses.length > 0 ? (
                    addresses.map((address, index) => {
                      const formattedAddress = formatAddress(address);
                      if (formattedAddress) {
                        return (
                          <Container as={Header} key={index}>
                            <Radio
                              label={formattedAddress}
                              name="address"
                              value={address}
                              checked={selectedAddress === address}
                              onChange={() => setSelectedAddress(address)}
                            />
                          </Container>
                        );
                      }
                      return null;
                    })
                  ) : (
                    <Message as="h5" color="red">
                      Please add a new address.
                    </Message>
                  )}
                </Container>
                <Modal
                  open={showNewAddressForm}
                  onClose={() => setShowNewAddressForm(false)}
                  dimmer="inverted"
                  size="small"
                >
                  <Modal.Header> Set New Address </Modal.Header>
                  <Modal.Content>
                    <Form>
                      <Form.Group widths={"equal"}>
                        <Form.Input
                          label="Address"
                          name="address"
                          value={newAddress.address}
                          onChange={handleNewAddressChange}
                        />
                        <Form.Input
                          label="City"
                          name="city"
                          value={newAddress.city}
                          onChange={handleNewAddressChange}
                        />
                        <Form.Input
                          label="State"
                          name="state"
                          value={newAddress.state}
                          onChange={handleNewAddressChange}
                        />
                      </Form.Group>
                      <Form.Group widths={"equal"}>
                        <Form.Input
                          label="Postal Code"
                          name="postalCode"
                          value={newAddress.postalCode}
                          onChange={handleNewAddressChange}
                        />
                        <Form.Input
                          label="Country"
                          name="country"
                          value={newAddress.country}
                          onChange={handleNewAddressChange}
                        />
                      </Form.Group>
                      <Button onClick={handleAddNewAddress}>
                        Add New Address
                      </Button>
                    </Form>
                  </Modal.Content>
                </Modal>
                <Button onClick={() => setShowNewAddressForm(true)}>
                  Add New Address
                </Button>
              </Segment>{" "}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Grid columns={"equal"} doubling stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Header as={"h4"}> Select Payment Method </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  {paymentMethod?.map((method) => (
                    <Grid.Column>
                      <Button
                        basic={selectedPaymentMethod !== method.name}
                        // circular
                        color="black"
                        onClick={() => {
                          setSelectedPaymentMethod(method.name);
                          setPaymentMethodId(method.id);
                        }}
                      >
                        {" "}
                        {method.name}
                      </Button>
                    </Grid.Column>
                  ))}
                </Grid.Row>
                {selectedPaymentMethod === "Cash on delivery" && (
                  <Grid.Row>
                    <Grid.Column>
                      <Message info>
                        <p>You have selected Cash on Delivery</p>
                      </Message>{" "}
                    </Grid.Column>
                  </Grid.Row>
                )}
                {selectedPaymentMethod === "Online Payment" && (
                  <Grid.Row>
                    <Grid.Column>
                      <Image src={QR} />
                      <Message info>
                        <p>
                          {" "}
                          Send us your payment screenshot on out whatsapp number
                          <b> 9849826321 </b>{" "}
                        </p>
                      </Message>
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Button
        icon
        floated="right"
        positive
        onClick={() => handleSubmit()}
        style={{ marginBottom: 20 }}
        // loading={isLoading || dataLoading}
        disabled={!selectedAddress || !selectedPaymentMethod}
      >
        {" "}
        Checkout <Icon name="arrow right" />
      </Button>
    </>
  );
}
