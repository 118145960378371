import { fetchAccountUserprofileList, fetchAccountUserprofileItem, createAccountUserprofileItem, updateAccountUserprofileItem, deleteAccountUserprofileItem } from '../actions/account' 
import { fetchChatRoomList, fetchChatRoomItem, createChatRoomItem, updateChatRoomItem, deleteChatRoomItem, fetchChatMessageList, fetchChatMessageItem, createChatMessageItem, updateChatMessageItem, deleteChatMessageItem } from '../actions/chat' 
import { fetchCustomerWholesalerList, fetchCustomerWholesalerItem, createCustomerWholesalerItem, updateCustomerWholesalerItem, deleteCustomerWholesalerItem, fetchCustomerRetailerList, fetchCustomerRetailerItem, createCustomerRetailerItem, updateCustomerRetailerItem, deleteCustomerRetailerItem, fetchCustomerIndividualList, fetchCustomerIndividualItem, createCustomerIndividualItem, updateCustomerIndividualItem, deleteCustomerIndividualItem } from '../actions/customer' 
import { fetchDeliveryDeliverycompanyList, fetchDeliveryDeliverycompanyItem, createDeliveryDeliverycompanyItem, updateDeliveryDeliverycompanyItem, deleteDeliveryDeliverycompanyItem, fetchDeliveryDeliverypersonList, fetchDeliveryDeliverypersonItem, createDeliveryDeliverypersonItem, updateDeliveryDeliverypersonItem, deleteDeliveryDeliverypersonItem, fetchDeliveryDeliveryorderList, fetchDeliveryDeliveryorderItem, createDeliveryDeliveryorderItem, updateDeliveryDeliveryorderItem, deleteDeliveryDeliveryorderItem, fetchDeliveryDeliverytrackingList, fetchDeliveryDeliverytrackingItem, createDeliveryDeliverytrackingItem, updateDeliveryDeliverytrackingItem, deleteDeliveryDeliverytrackingItem } from '../actions/delivery' 
import { fetchFeedbackFeedbackList, fetchFeedbackFeedbackItem, createFeedbackFeedbackItem, updateFeedbackFeedbackItem, deleteFeedbackFeedbackItem } from '../actions/feedback' 
import { fetchNotificationNotificationList, fetchNotificationNotificationItem, createNotificationNotificationItem, updateNotificationNotificationItem, deleteNotificationNotificationItem, fetchNotificationNotificationtypeList, fetchNotificationNotificationtypeItem, createNotificationNotificationtypeItem, updateNotificationNotificationtypeItem, deleteNotificationNotificationtypeItem, fetchNotificationUsernotificationsettingsList, fetchNotificationUsernotificationsettingsItem, createNotificationUsernotificationsettingsItem, updateNotificationUsernotificationsettingsItem, deleteNotificationUsernotificationsettingsItem } from '../actions/notification' 
import { fetchOrderOrderList, fetchOrderOrderItem, createOrderOrderItem, updateOrderOrderItem, deleteOrderOrderItem, fetchOrderOrderitemList, fetchOrderOrderitemItem, createOrderOrderitemItem, updateOrderOrderitemItem, deleteOrderOrderitemItem } from '../actions/order' 
import { fetchPackagingPackagingtypeList, fetchPackagingPackagingtypeItem, createPackagingPackagingtypeItem, updatePackagingPackagingtypeItem, deletePackagingPackagingtypeItem, fetchPackagingPackagingList, fetchPackagingPackagingItem, createPackagingPackagingItem, updatePackagingPackagingItem, deletePackagingPackagingItem, fetchPackagingPackageditemList, fetchPackagingPackageditemItem, createPackagingPackageditemItem, updatePackagingPackageditemItem, deletePackagingPackageditemItem } from '../actions/packaging' 
import { fetchPaymentPaymentmethodList, fetchPaymentPaymentmethodItem, createPaymentPaymentmethodItem, updatePaymentPaymentmethodItem, deletePaymentPaymentmethodItem, fetchPaymentPaymenttransactionList, fetchPaymentPaymenttransactionItem, createPaymentPaymenttransactionItem, updatePaymentPaymenttransactionItem, deletePaymentPaymenttransactionItem } from '../actions/payment' 
import { fetchProductCategoryList, fetchProductCategoryItem, createProductCategoryItem, updateProductCategoryItem, deleteProductCategoryItem, fetchProductProductList, fetchProductProductItem, createProductProductItem, updateProductProductItem, deleteProductProductItem } from '../actions/product' 
import { fetchStoreStoreList, fetchStoreStoreItem, createStoreStoreItem, updateStoreStoreItem, deleteStoreStoreItem, fetchStoreRoomList, fetchStoreRoomItem, createStoreRoomItem, updateStoreRoomItem, deleteStoreRoomItem } from '../actions/store' 


export const func_map = {

    'fetchAccountUserprofileList': fetchAccountUserprofileList,
    'fetchAccountUserprofileItem': fetchAccountUserprofileItem,
    'createAccountUserprofileItem': createAccountUserprofileItem,
    'updateAccountUserprofileItem': updateAccountUserprofileItem,
    'deleteAccountUserprofileItem': deleteAccountUserprofileItem,

    'fetchChatRoomList': fetchChatRoomList,
    'fetchChatRoomItem': fetchChatRoomItem,
    'createChatRoomItem': createChatRoomItem,
    'updateChatRoomItem': updateChatRoomItem,
    'deleteChatRoomItem': deleteChatRoomItem,

    'fetchChatMessageList': fetchChatMessageList,
    'fetchChatMessageItem': fetchChatMessageItem,
    'createChatMessageItem': createChatMessageItem,
    'updateChatMessageItem': updateChatMessageItem,
    'deleteChatMessageItem': deleteChatMessageItem,

    'fetchCustomerWholesalerList': fetchCustomerWholesalerList,
    'fetchCustomerWholesalerItem': fetchCustomerWholesalerItem,
    'createCustomerWholesalerItem': createCustomerWholesalerItem,
    'updateCustomerWholesalerItem': updateCustomerWholesalerItem,
    'deleteCustomerWholesalerItem': deleteCustomerWholesalerItem,

    'fetchCustomerRetailerList': fetchCustomerRetailerList,
    'fetchCustomerRetailerItem': fetchCustomerRetailerItem,
    'createCustomerRetailerItem': createCustomerRetailerItem,
    'updateCustomerRetailerItem': updateCustomerRetailerItem,
    'deleteCustomerRetailerItem': deleteCustomerRetailerItem,

    'fetchCustomerIndividualList': fetchCustomerIndividualList,
    'fetchCustomerIndividualItem': fetchCustomerIndividualItem,
    'createCustomerIndividualItem': createCustomerIndividualItem,
    'updateCustomerIndividualItem': updateCustomerIndividualItem,
    'deleteCustomerIndividualItem': deleteCustomerIndividualItem,

    'fetchDeliveryDeliverycompanyList': fetchDeliveryDeliverycompanyList,
    'fetchDeliveryDeliverycompanyItem': fetchDeliveryDeliverycompanyItem,
    'createDeliveryDeliverycompanyItem': createDeliveryDeliverycompanyItem,
    'updateDeliveryDeliverycompanyItem': updateDeliveryDeliverycompanyItem,
    'deleteDeliveryDeliverycompanyItem': deleteDeliveryDeliverycompanyItem,

    'fetchDeliveryDeliverypersonList': fetchDeliveryDeliverypersonList,
    'fetchDeliveryDeliverypersonItem': fetchDeliveryDeliverypersonItem,
    'createDeliveryDeliverypersonItem': createDeliveryDeliverypersonItem,
    'updateDeliveryDeliverypersonItem': updateDeliveryDeliverypersonItem,
    'deleteDeliveryDeliverypersonItem': deleteDeliveryDeliverypersonItem,

    'fetchDeliveryDeliveryorderList': fetchDeliveryDeliveryorderList,
    'fetchDeliveryDeliveryorderItem': fetchDeliveryDeliveryorderItem,
    'createDeliveryDeliveryorderItem': createDeliveryDeliveryorderItem,
    'updateDeliveryDeliveryorderItem': updateDeliveryDeliveryorderItem,
    'deleteDeliveryDeliveryorderItem': deleteDeliveryDeliveryorderItem,

    'fetchDeliveryDeliverytrackingList': fetchDeliveryDeliverytrackingList,
    'fetchDeliveryDeliverytrackingItem': fetchDeliveryDeliverytrackingItem,
    'createDeliveryDeliverytrackingItem': createDeliveryDeliverytrackingItem,
    'updateDeliveryDeliverytrackingItem': updateDeliveryDeliverytrackingItem,
    'deleteDeliveryDeliverytrackingItem': deleteDeliveryDeliverytrackingItem,

    'fetchFeedbackFeedbackList': fetchFeedbackFeedbackList,
    'fetchFeedbackFeedbackItem': fetchFeedbackFeedbackItem,
    'createFeedbackFeedbackItem': createFeedbackFeedbackItem,
    'updateFeedbackFeedbackItem': updateFeedbackFeedbackItem,
    'deleteFeedbackFeedbackItem': deleteFeedbackFeedbackItem,

    'fetchNotificationNotificationList': fetchNotificationNotificationList,
    'fetchNotificationNotificationItem': fetchNotificationNotificationItem,
    'createNotificationNotificationItem': createNotificationNotificationItem,
    'updateNotificationNotificationItem': updateNotificationNotificationItem,
    'deleteNotificationNotificationItem': deleteNotificationNotificationItem,

    'fetchNotificationNotificationtypeList': fetchNotificationNotificationtypeList,
    'fetchNotificationNotificationtypeItem': fetchNotificationNotificationtypeItem,
    'createNotificationNotificationtypeItem': createNotificationNotificationtypeItem,
    'updateNotificationNotificationtypeItem': updateNotificationNotificationtypeItem,
    'deleteNotificationNotificationtypeItem': deleteNotificationNotificationtypeItem,

    'fetchNotificationUsernotificationsettingsList': fetchNotificationUsernotificationsettingsList,
    'fetchNotificationUsernotificationsettingsItem': fetchNotificationUsernotificationsettingsItem,
    'createNotificationUsernotificationsettingsItem': createNotificationUsernotificationsettingsItem,
    'updateNotificationUsernotificationsettingsItem': updateNotificationUsernotificationsettingsItem,
    'deleteNotificationUsernotificationsettingsItem': deleteNotificationUsernotificationsettingsItem,

    'fetchOrderOrderList': fetchOrderOrderList,
    'fetchOrderOrderItem': fetchOrderOrderItem,
    'createOrderOrderItem': createOrderOrderItem,
    'updateOrderOrderItem': updateOrderOrderItem,
    'deleteOrderOrderItem': deleteOrderOrderItem,

    'fetchOrderOrderitemList': fetchOrderOrderitemList,
    'fetchOrderOrderitemItem': fetchOrderOrderitemItem,
    'createOrderOrderitemItem': createOrderOrderitemItem,
    'updateOrderOrderitemItem': updateOrderOrderitemItem,
    'deleteOrderOrderitemItem': deleteOrderOrderitemItem,

    'fetchPackagingPackagingtypeList': fetchPackagingPackagingtypeList,
    'fetchPackagingPackagingtypeItem': fetchPackagingPackagingtypeItem,
    'createPackagingPackagingtypeItem': createPackagingPackagingtypeItem,
    'updatePackagingPackagingtypeItem': updatePackagingPackagingtypeItem,
    'deletePackagingPackagingtypeItem': deletePackagingPackagingtypeItem,

    'fetchPackagingPackagingList': fetchPackagingPackagingList,
    'fetchPackagingPackagingItem': fetchPackagingPackagingItem,
    'createPackagingPackagingItem': createPackagingPackagingItem,
    'updatePackagingPackagingItem': updatePackagingPackagingItem,
    'deletePackagingPackagingItem': deletePackagingPackagingItem,

    'fetchPackagingPackageditemList': fetchPackagingPackageditemList,
    'fetchPackagingPackageditemItem': fetchPackagingPackageditemItem,
    'createPackagingPackageditemItem': createPackagingPackageditemItem,
    'updatePackagingPackageditemItem': updatePackagingPackageditemItem,
    'deletePackagingPackageditemItem': deletePackagingPackageditemItem,

    'fetchPaymentPaymentmethodList': fetchPaymentPaymentmethodList,
    'fetchPaymentPaymentmethodItem': fetchPaymentPaymentmethodItem,
    'createPaymentPaymentmethodItem': createPaymentPaymentmethodItem,
    'updatePaymentPaymentmethodItem': updatePaymentPaymentmethodItem,
    'deletePaymentPaymentmethodItem': deletePaymentPaymentmethodItem,

    'fetchPaymentPaymenttransactionList': fetchPaymentPaymenttransactionList,
    'fetchPaymentPaymenttransactionItem': fetchPaymentPaymenttransactionItem,
    'createPaymentPaymenttransactionItem': createPaymentPaymenttransactionItem,
    'updatePaymentPaymenttransactionItem': updatePaymentPaymenttransactionItem,
    'deletePaymentPaymenttransactionItem': deletePaymentPaymenttransactionItem,

    'fetchProductCategoryList': fetchProductCategoryList,
    'fetchProductCategoryItem': fetchProductCategoryItem,
    'createProductCategoryItem': createProductCategoryItem,
    'updateProductCategoryItem': updateProductCategoryItem,
    'deleteProductCategoryItem': deleteProductCategoryItem,

    'fetchProductProductList': fetchProductProductList,
    'fetchProductProductItem': fetchProductProductItem,
    'createProductProductItem': createProductProductItem,
    'updateProductProductItem': updateProductProductItem,
    'deleteProductProductItem': deleteProductProductItem,

    'fetchStoreStoreList': fetchStoreStoreList,
    'fetchStoreStoreItem': fetchStoreStoreItem,
    'createStoreStoreItem': createStoreStoreItem,
    'updateStoreStoreItem': updateStoreStoreItem,
    'deleteStoreStoreItem': deleteStoreStoreItem,

    'fetchStoreRoomList': fetchStoreRoomList,
    'fetchStoreRoomItem': fetchStoreRoomItem,
    'createStoreRoomItem': createStoreRoomItem,
    'updateStoreRoomItem': updateStoreRoomItem,
    'deleteStoreRoomItem': deleteStoreRoomItem,
}