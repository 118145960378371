import ProfileCard from "components/common/ProfileCard";
import MyProfile from "pages/Profile";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Header, Form, Button } from "semantic-ui-react";
import { configureAccountUserprofile } from "services/access/account";
import { utils } from "services/api-data/src/App";

const AdminSettingsPage = () => {
  // Dummy settings data
  const [profileData, setProfileData] = useState();
  const user_id = localStorage.getItem("user_id");
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(
    utils,
    dispatch,
    state
  );

  useEffect(() => {
    action.fetchUserprofileItem(user_id);
  }, [user_id]);
  const [settings, setSettings] = useState({
    companyName: "",
    email: "",
    address: "",
    phone: "",
    currency: "USD",
    timezone: "UTC",
  });

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      setProfileData(data?.userprofile_fetch_item);
    }
  }, [data?.userprofile_fetch_item]);

  useEffect(() => {
    if (profileData) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        companyName: profileData.business_name,
        email: profileData.email,
        phone: profileData.contact_number,
        address: `${profileData?.country} ,
        ${profileData?.state} , ${profileData?.city}
        ${profileData?.address} ,
        ${profileData?.postal_code} `,
      }));
    }
  }, [profileData]);

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Dummy logic to submit settings data to backend
    console.log("Settings submitted:", settings);
  };

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  return (
    <Container>
      <Header as="h1">Settings</Header>
      {/* <Form onSubmit={handleSubmit}>
        <Form.Field>
          <label>Company Name</label>
          <input
            name="companyName"
            value={settings.companyName}
            onChange={handleChange}
            placeholder="Company Name"
          />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <input
            name="email"
            value={settings.email}
            onChange={handleChange}
            placeholder="Email"
          />
        </Form.Field>
        <Form.Field>
          <label>Address</label>
          <input
            name="address"
            value={settings.address}
            onChange={handleChange}
            placeholder="Address"
          />
        </Form.Field>
        <Form.Field>
          <label>Phone</label>
          <input
            name="phone"
            value={settings.phone}
            onChange={handleChange}
            placeholder="Phone"
          />
        </Form.Field>
        <Form.Field>
          <label>Currency</label>
          <input
            name="currency"
            value={settings.currency}
            onChange={handleChange}
            placeholder="Currency"
          />
        </Form.Field>
        <Form.Field>
          <label>Timezone</label>
          <input
            name="timezone"
            value={settings.timezone}
            onChange={handleChange}
            placeholder="Timezone"
          />
        </Form.Field>
        <Button primary type="submit">
          Save Settings
        </Button>
      </Form> */}
      <ProfileCard />
    </Container>
  );
};

export default AdminSettingsPage;
