import React, { useState, useEffect } from "react";
import {
  Container,
  Header,
  Button,
  Modal,
  Form,
  Input,
  Table,
  Icon,
  Grid,
  Pagination,
  Loader,
  Dropdown,
  Segment,
  Divider,
} from "semantic-ui-react";

import {
  PAGE_LIMIT,
  configureOrderOrder,
  configureOrderOrderitem,
} from "services/access/order";
import { configureProductProduct } from "services/access/product";
import { utils } from "services/api-data";
import { useDispatch, useSelector } from "react-redux";
import { configureAccountUserprofile } from "services/access/account";
import { configureDeliveryDeliveryorder } from "services/access/delivery";
import { configurePaymentPaymenttransaction } from "services/access/payment";
import { GetPaymentMethodName } from "./payments";

export const OrderCustomerUsername = ({ id }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(
    utils,
    dispatch,
    state
  );
  const [profileData, setProfileData] = useState();
  useEffect(() => {
    action.fetchUserprofileItem(id);
  }, []);

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      setProfileData(data?.userprofile_fetch_item);
    }
  }, [data?.userprofile_fetch_item]);

  return (
    <span>
      {id
        ? profileData?.first_name && profileData?.last_name
          ? `${profileData.first_name} ${profileData.last_name}`
          : profileData?.business_name
        : "Anonymous"}
    </span>
  );
};

const OrderItemsDetails = ({
  selectedOrder,
  setSelectedOrder,
  openOrderModal,
  setOpenOrderModal,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureOrderOrderitem(
    utils,
    dispatch,
    state
  );
  const [orderItems, setOrderItems] = useState();
  const [products, setProducts] = useState();
  const [deliveryData, setDeliveryData] = useState();
  const [transactionData, setTransactionData] = useState();
  const product_config = configureProductProduct(utils, dispatch, state);
  const delivery_config = configureDeliveryDeliveryorder(
    utils,
    dispatch,
    state
  );
  const payment_config = configurePaymentPaymenttransaction(
    utils,
    dispatch,
    state
  );

  useEffect(() => {
    if (selectedOrder) {
      action.fetchOrderitemList(selectedOrder);
      product_config?.action.fetchProductList();
      delivery_config?.action.fetchDeliveryorderItem(selectedOrder);
      payment_config.action.fetchPaymentTransactionByOrder(selectedOrder);
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (data?.orderitem_fetch_list) {
      setOrderItems(data?.orderitem_fetch_list);
    }
  }, [data?.orderitem_fetch_list]);

  useEffect(() => {
    if (product_config?.data?.product_fetch_list) {
      setProducts(product_config?.data?.product_fetch_list);
    }
  }, [product_config?.data?.product_fetch_list]);

  useEffect(() => {
    if (delivery_config?.data?.deliveryorder_fetch_item) {
      setDeliveryData(delivery_config?.data?.deliveryorder_fetch_item);
    }
  }, [delivery_config?.data?.deliveryorder_fetch_item]);

  useEffect(() => {
    if (payment_config.data.paymenttransaction_fetch_list) {
      setTransactionData(payment_config.data.paymenttransaction_fetch_list);
    }
  }, [payment_config.data.paymenttransaction_fetch_list]);

  const getProductById = (productId) => {
    const product = products?.find((product) => product.id === productId);
    return product ? product.name : "Unknown Product";
  };

  const calculateTotalAmount = (item_data) => {
    return item_data
      .map((c) => c.unit_price * c.quantity)
      .reduce((a, b) => parseInt(a) + parseInt(b), 0);
  };

  return (
    <Modal
      open={openOrderModal}
      onClose={() => {
        setSelectedOrder(null);
        setOpenOrderModal(false);
      }}
      dimmer="inverted"
    >
      <Modal.Header>Order Details</Modal.Header>
      <Modal.Content>
        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell> Product Name </Table.HeaderCell>
              <Table.HeaderCell> Quantity </Table.HeaderCell>
              <Table.HeaderCell> Unit Price </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {orderItems?.length > 0 ? (
            <>
              {orderItems?.map((items) => (
                <Table.Row>
                  <Table.Cell>
                    {" "}
                    <b> {getProductById(items.product)}</b>
                  </Table.Cell>
                  <Table.Cell>{items?.quantity} </Table.Cell>
                  <Table.Cell> {items?.unit_price} </Table.Cell>
                </Table.Row>
              ))}
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan={2}>
                    {" "}
                    <Header as={"h3"}> Total Price </Header>{" "}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {" "}
                    {calculateTotalAmount(orderItems)}{" "}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </>
          ) : (
            <Table.Row>
              {" "}
              <Table.Cell> No order Items found </Table.Cell>{" "}
            </Table.Row>
          )}
        </Table>
        <div>
          <Grid columns={2} stackable doubling stretched>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Header as={"h2"}> User Details </Header>
                  <Header as={"h3"}> Contact Details </Header>

                  <p>Contact Number : {deliveryData?.contact_number}</p>
                  <Divider />
                  <Header as={"h3"}> Delivery Address </Header>
                  <p>
                    {" "}
                    {deliveryData?.country} , {deliveryData?.state} ,{" "}
                    {deliveryData?.city} {deliveryData?.address} ,
                    {deliveryData?.postal_code}{" "}
                  </p>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <Header as={"h2"}> Payment Details</Header>

                  {transactionData?.map((transaction) => (
                    <Segment>
                      <Header as={"h3"}> Payment Id : {transaction.id} </Header>
                      <Header as={"h3"}> Payment Method </Header>
                      <GetPaymentMethodName id={transaction?.payment_method} />
                      <Header as={"h3"}> Payment Status </Header>{" "}
                      <p>{transaction.status}</p>
                    </Segment>
                  ))}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setSelectedOrder(null);
            setOpenOrderModal(false);
          }}
          negative
        >
          {" "}
          Close{" "}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const OrdersPage = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureOrderOrder(
    utils,
    dispatch,
    state
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const itemsPerpage = PAGE_LIMIT;
  const [triggerAction, setTriggerAction] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    fetchMore(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setLoading(data?.order[`order_page_${currentPage}_loading`]);
  }, [currentPage, data?.order]);

  const fetchMore = (page) => {
    action.getPages(page);
  };

  useEffect(() => {
    const pageCount = data?.order[`order_page_${currentPage}`]?.count || 0;
    setTotalPages(Math.ceil(pageCount / itemsPerpage));
    setOrders(data?.order[`order_page_${currentPage}`]?.results);
  }, [data?.order, currentPage]);

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const statusOptions = [
    { key: "Pending", value: "Pending", text: "Pending" },
    { key: "Confirmed", value: "Confirmed", text: "Confirmed" },
    { key: "Processing", value: "Processing", text: "Processing" },
    { key: "Shipped", value: "Shipped", text: "Shipped" },
    {
      key: "Out for Delivery",
      value: "Out for Delivery",
      text: "Out for Delivery",
    },
    { key: "Delivered", value: "Delivered", text: "Delivered" },
    { key: "Completed", value: "Completed", text: "Completed" },
    { key: "Cancelled", value: "Cancelled", text: "Cancelled" },
    { key: "Returned", value: "Returned", text: "Returned" },
    { key: "Refunded", value: "Refunded", text: "Refunded" },
    { key: "Failed", value: "Failed", text: "Failed" },
    { key: "On Hold", value: "On Hold", text: "On Hold" },
    {
      key: "Partially Shipped",
      value: "Partially Shipped",
      text: "Partially Shipped",
    },
    {
      key: "Awaiting Payment",
      value: "Awaiting Payment",
      text: "Awaiting Payment",
    },
    {
      key: "Awaiting Fulfillment",
      value: "Awaiting Fulfillment",
      text: "Awaiting Fulfillment",
    },
    { key: "Backordered", value: "Backordered", text: "Backordered" },
  ];

  const handleStatusChange = async (e, { value }, orderId) => {
    await action.updateOrderItem(orderId, { status: value });
    setTriggerAction(true);
  };

  useEffect(() => {
    if (triggerAction) {
      if (!loading) {
        fetchMore(currentPage);
        setTriggerAction(false);
      }
    }
  }, [loading, triggerAction]);

  return (
    <Container>
      {loading ? (
        <Loader active inline />
      ) : (
        <>
          <Header as="h2">Orders</Header>
          <Table celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Customer</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell> Actions </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {orders?.length > 0 ? (
                orders.map((order) => (
                  <Table.Row key={order.id}>
                    <Table.Cell>{order.id}</Table.Cell>
                    <Table.Cell>
                      <OrderCustomerUsername id={order.customer} />
                    </Table.Cell>
                    <Table.Cell>{order.total_amount}</Table.Cell>
                    <Table.Cell>
                      {" "}
                      <Dropdown
                        fluid
                        selection
                        options={statusOptions}
                        value={order.status}
                        onChange={(e, { value }) =>
                          handleStatusChange(e, { value }, order.id)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        basic
                        icon
                        secondary
                        onClick={() => {
                          setSelectedOrder(order.id);
                          setOpenOrderModal(true);
                        }}
                      >
                        {" "}
                        <Icon name="eye" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  {" "}
                  <Table.Cell colSpan={5} textAlign="center">
                    {" "}
                    <h3> No Orders Found </h3>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Grid centered>
            <Pagination
              pointing
              secondary
              activePage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Grid>
        </>
      )}

      <OrderItemsDetails
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        openOrderModal={openOrderModal}
        setOpenOrderModal={setOpenOrderModal}
      />
    </Container>
  );
};

export default OrdersPage;
